<mat-card appearance="outlined" [class]="'card ' + classes "
          [class.card-category]="title"
          [class.roam-card-compact]="cardCompact()"
>
  <mat-card-title  [ngClass]="{ 'pt-24' : !title}">
    <div class="justify-between capitalized" *ngIf="title">
      <div [class]="titleCls">
        {{ title }}
      </div>
      <div class="act" *ngIf="act" (click)="onAct.emit($event)">
        {{ act }}
      </div>
      <ng-content select="[actRef]"></ng-content>
    </div>
  </mat-card-title>
  <mat-card-content>
    <ng-content></ng-content>
  </mat-card-content>
  <div class="footer" *ngIf="footer">
    <div class="content">
      <ng-content select="[footer]"></ng-content>
    </div>
  </div>
</mat-card>
