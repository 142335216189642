import { input, Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { MatCardModule } from "@angular/material/card";
import { CommonModule } from "@angular/common";

@Component({
  standalone:true,
  imports:[CommonModule, MatCardModule],
  selector: "app-roam-card",
  templateUrl: "./roam-card.component.html",
  styleUrls: ["./roam-card.component.scss"],
})
export class RoamCardComponent implements OnInit {
  @Input()
  public title: string = "";

  @Input()
  public footer: boolean = false;

  @Input()
  public act: string = "";

  @Input()
  public classes: string = "";

  readonly cardCompact = input(false, {
    alias: "roamCardCompact",
    transform: (v) => {
      return v === true || v === "";
    }
  });

  @Input()
  public titleCls: string = "font-18px";

  @Output()
  public onAct: EventEmitter<Event> = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}
}
